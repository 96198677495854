import { useRouter } from 'next/router';
import Image from 'next/image';
import Head from 'next/head';

import { Button } from '@/components/atoms/Button';

const Custom404 = () => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="flex justify-between flex-col-reverse items-center regular:flex-row py-8 gap-12 m-auto px-4 w-full max-w-5xl">
        <div>
          <h2 className="text-center regular:text-start font-bold text-primary-600 text-7xl regular:text-8xl">404</h2>
          <h2 className="text-center regular:text-start text-white font-bold text-4xl regular:text-5xl mt-9">
            Ups... pudło!
          </h2>
          <p className="text-center regular:text-start text-white text-base regular:text-xl mt-2">
            Strona o takim adresie nie istnieje
          </p>
          <Button
            className="mt-12 regular:mt-16 mx-auto regular:mx-0 block"
            variant="outlined"
            onClick={() => {
              void router.push('/');
            }}
          >
            Strona główna
          </Button>
        </div>
        <div className="relative w-[216px] h-[240px] regular:w-[460px] regular:h-[512px]">
          <Image src="/images/404-illustration.svg" alt="404 illustration" layout="fill" />
        </div>
      </div>
    </>
  );
};

export default Custom404;
